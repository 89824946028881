import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PostServiceService {
  hostName: any = config.hostName;

  constructor(
    private http: HttpClient
  ) { }

  updateUserProfile(reqBody: object) {
    let url = this.hostName + "/api/user/update-profile";
    return this.http.put<any>(url, reqBody);
  }

  updateUserHandle(reqBody: object) {
    let url = this.hostName + "/api/user/update-handle";
    return this.http.put<any>(url, reqBody);
  }

  iveBeenDucked(reqBody: object) {
    let url = this.hostName + "/api/ducking/iveBeenDucked";
    return this.http.put<any>(url, reqBody);
  }

  iJustDucked(reqBody: object) {
    let url = this.hostName + "/api/ducking/createDucking";
    return this.http.put<any>(url, reqBody);
  }

  leaderboard(reqBody: object) {
    let url = this.hostName + "/api/ducking/leaderboard";
    return this.http.post<any>(url, reqBody);
  }

  forgotPassword(reqBody: object) {
    let url = this.hostName + "/api/auth/forgot-password";
    return this.http.post<any>(url, reqBody);
  }

  resetPassword(reqBody: object) {
    let url = this.hostName + "/api/auth/reset-password";
    return this.http.post<any>(url, reqBody);
  }

  updatePassword(reqBody: object) {
    let url = this.hostName + "/api/user/update-password";
    return this.http.post<any>(url, reqBody);
  }

  putCreateUser(reqBody: object) {
    let url = this.hostName + "/api/auth/create";
    return this.http.put<any>(url, reqBody);
  }

  putUpdateSocialSignInUser(reqBody: object) {
    let url = this.hostName + "/api/user/updateSocialSignInDetails";
    return this.http.put<any>(url, reqBody);
  }

  replyMessage(reqBody: object) {
    let url = this.hostName + "/api/user/reply-message";
    return this.http.put<any>(url, reqBody);
  }

  deleteMessage(messageId: string) {
    let url = this.hostName + "/api/user/delete-message?messageId=" + messageId;
    return this.http.delete<any>(url, {});
  }

  putUpdatelocationSharing(reqBody: object) {
    let url = this.hostName + "/api//user/update-location-permission";
    return this.http.put<any>(url, reqBody);
  }

  unreadMessageCount(reqBody: object) {
    let url = this.hostName + "/api/user/unread-message-count";
    return this.http.put<any>(url, reqBody);
  }

  logout(reqBody: object) {
    let url = this.hostName + "/api/user/remove-deviceId";
    return this.http.post<any>(url, reqBody);
  }

  duckingShareUpdate(reqBody: object) {
    let url = this.hostName + "/api/ducking/duckingShareUpdate";
    return this.http.post<any>(url, reqBody);
  }

  updateDuckingShareDefaultSettings(reqBody: object) {
    let url = this.hostName + "/api/user/updateDuckingShareDefaultSettings";
    return this.http.post<any>(url, reqBody);
  }

  addSupport(reqBody: object) {
    let url = this.hostName + "/api/support/add";
    return this.http.put<any>(url, reqBody);
  }

  duckingsDeleteById(reqBody: object) {
    let url = this.hostName + "/api/ducking/deleteDucking";
    return this.http.post<any>(url, reqBody);
  }

  likeServiceCall(reqBody: object) {
    let url = this.hostName + "/api/ducking/likePost";
    return this.http.post<any>(url, reqBody);
  }

  feedComment(reqBody: object) {
    let url = this.hostName + "/api/ducking/feedComment";
    return this.http.post<any>(url, reqBody);
  }

  listComments(reqBody: object) {
    let url = this.hostName + "/api/ducking/commentList";
    return this.http.post<any>(url, reqBody);
  }

  likeCommentServiceCall(reqBody: object) {
    let url = this.hostName + "/api/ducking/likeComment";
    return this.http.post<any>(url, reqBody);
  }

  listUserProfileDucking(reqBody: any) {
    let url = this.hostName + "/api/ducking/listUserProfileDucking";
    return this.http.post<any>(url, reqBody);
  }

}
