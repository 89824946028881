import { Injectable } from '@angular/core';
declare var google:any;

@Injectable({
  providedIn: 'root'
})
export class JsMapsProviderService {
  
public map: any;
  constructor() { }

  init(location:any, element:any){
    console.log('location in js map',location.latitude);
    console.log('element in js mapppp',element);
    let latLng = new google.maps.LatLng(location.latitude, location.longitude);

    console.log('latLng in map js',latLng);

    let opts = {
      center: latLng,
      zoom: 11,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      myLocation:true,
    };

    console.log('opts in js mapppp',opts);
    this.map = new google.maps.Map(element.nativeElement, opts);
    console.log('this.map this.map this.map ',this.map);
  }
}
