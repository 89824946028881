import { NgZone } from "@angular/core";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AlertController, NavController } from "@ionic/angular";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";


@Injectable()
export class Intercept implements HttpInterceptor {

  constructor(
    private router: Router,
    private navCtrl: NavController,
    public alertController: AlertController,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let userData: any = {};
    if (localStorage.getItem("user")) {
      let tempData: any = localStorage.getItem("user");
      userData = JSON.parse(tempData);
    }

    if (userData && userData.accessToken) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${userData.accessToken}` }
      });
    } else {
      console.log('if no token');
      // if (this.router.url != "/login") {
      //   if ((this.router.url != "/signup") && (this.router.url != "/forgot-password") && (this.router.url != "/reset-password/*")) {
          
      //     this.navCtrl.navigateRoot("/login");
      //   }
      // }
      if (this.router.url != "/login") {
        if ((this.router.url == "/signup") || (this.router.url == "/forgot-password") || (this.router.url.includes('reset-password'))) {
          console.log('this.router.url',this.router.url);
          console.log('no redirect to login');
          // this.alertPopup('Error', "Unauthorized Access");
        }else{
          this.alertPopup('Error', "Unauthorized Access");
          this.navCtrl.navigateRoot("/login");
        }
      }
    }

    // return next.handle(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = { ...error };
        console.log(error, "errorr");

        if (
          data.status == 401 &&
          this.router.url != "/login"
        ) {
          // asked to remove by matt
          // this.alertPopup(
          //   "Error",
          //   "You were logged out. Please log in again."
          // );
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.navCtrl.navigateRoot("/login");
        }
        return throwError(error);
      })
    );

  }

  async alertPopup(title: string, msg: string) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: [{
        text: 'Okay'
      }]
    });
    await alert.present();
  }

}
