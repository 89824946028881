import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { config } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public loginData = '';
  hostName: any = config.hostName;

  constructor(
    private http: HttpClient,
    public navCtrl: NavController,
    public router:Router
  ) {
    this.checkToken();
  }

  async checkToken() {
    console.log('check token ---->');
    const user = await localStorage.getItem('user');
    if (user && user != "undefined") {
      this.loginData = JSON.parse(user);
      // this.navCtrl.navigateForward('');
      console.log('Already logged in!');
    } else {
      if (this.router.url != "/login") {
        if ((this.router.url == "/signup") || (this.router.url == "/forgot-password") || (this.router.url.includes('reset-password'))) {
          console.log('this.router.url',this.router.url);
          console.log('no redirect to login');
          // this.alertPopup('Error', "Unauthorized Access");
        }else{
          this.navCtrl.navigateRoot("/login");
        }
      }
    }
  }

  login(formData: any) {
    let url = this.hostName + "/api/auth/login";
    return this.http.post<any>(url, formData);
  }

  socialLogin(formData: any) {
    let url = this.hostName + "/api/auth/social-login";
    return this.http.post<any>(url, formData);
  }

  getHandleAvailability(handle: string) {
    let url = this.hostName + "/api/auth/available/" + handle;
    return this.http.get<any>(url);
  }

  postForGotPassword(reqBody:any){
    let url = this.hostName + "/api/auth/forgot-password";
    return this.http.post<any>(url, reqBody);
  }

  postResetPassword(reqBody:any){
    let url = this.hostName + "/api/auth/reset-password";
    return this.http.post<any>(url, reqBody);
  }

  postChangePassword(reqBody:any){
    let url = this.hostName + "/api/user/update-password";
    return this.http.post<any>(url, reqBody);
  }

  deleteMyRecord(){
    let url = this.hostName + "/api/user/delete-user";
    return this.http.post<any>(url, {});
  }

}
