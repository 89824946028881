import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetServiceService {
  hostName: any = config.hostName;

  constructor(
    private http: HttpClient
  ) { }

  getUserProfile() {
    let url = this.hostName + "/api/user/profile";
    return this.http.get<any>(url);
  }

  getLatestDuckings(lat: any, long: any) {
    let url = '';
    if (lat && long) {
      url = this.hostName + "/api/ducking/listLatestDuckings?lat=" + lat + "&long=" + long;
    } else {
      url = this.hostName + "/api/ducking/listLatestDuckings";
    }
    return this.http.get<any>(url);
  }

  getMyDucks(pageType: any, pageSize: any, page: any, sortActive: any, sortType: any) {
    // let url = this.hostName + "/api/ducking/listMyDucks?pageType=" + reqBody.pageType;

    let url = "";
    if (pageSize) {
      url =
        this.hostName +
        "/api/ducking/listMyDucks?pageType=" + pageType +
        "&pageSize=" +
        pageSize +
        "&page=" +
        (page + 1) +
        '&sortKey=' +
        sortActive +
        '&sortOrder=' + (sortType == "asc" ? 1 : -1);
    } else {
      url = this.hostName + "/api/ducking/listMyDucks";
    }
    return this.http.get<any>(url);
  }

  getMyMessages(pageType: any, pageSize: any, page: any, sortActive: any, sortType: any) {
    // pass pageType 1 for messages received, 2 for messages sent
    let url = "";
    if (pageSize) {
      url =
        this.hostName +
        "/api/user/list-messages?pageType=" + pageType +
        "&pageSize=" +
        pageSize +
        "&page=" +
        (page + 1) +
        '&sortKey=' +
        sortActive +
        '&sortOrder=' + (sortType == "asc" ? 1 : -1);
    } else {
      url = this.hostName + "/api/user/list-messages";
    }
    return this.http.get<any>(url);
  }

  getMessageDetail(messageId: any) {
    let url = this.hostName + "/api/user/message-detail?messageId=" + messageId;
    return this.http.get<any>(url);
  }

  getMessageUsers() {
    let url = this.hostName + "/api/user/list-message-users";
    return this.http.get<any>(url);
  }

  getReplyList(toHandle: any) {
    let url = this.hostName + "/api/user/list-reply?toHandle=" + toHandle;
    return this.http.get<any>(url);
  }

  productsList() {
    let url = this.hostName + "/api/product/shopping";
    return this.http.get<any>(url);
  }

  getProjectDetails(productId: any) {
    let url = this.hostName + "/api/product/product-detail?productId=" + productId;
    return this.http.get<any>(url);
  }

  getDuckingDetails(duckingId: any) {
    let url = this.hostName + "/api/ducking/duckingDetails?duckingId=" + duckingId;
    return this.http.get<any>(url);
  }

  getProfilePicture(handle: any) {
    let url = this.hostName + "/api/auth/profileImage.png?handle=" + handle;
    return this.http.get<any>(url);
  }

  getListDuckersByHandle(handle: any, pageSize: any, page: any, sortActive: any, sortType: any) {
    // pass pageType 1 for messages received, 2 for messages sent
    let url = "";
    if (pageSize) {
      url =
        this.hostName +
        "/api/ducking/listDucksByHandle?handle=" + handle +
        "&pageSize=" +
        pageSize +
        "&page=" +
        (page + 1) +
        '&sortKey=' +
        sortActive +
        '&sortOrder=' + (sortType == "asc" ? 1 : -1);
    }
    return this.http.get<any>(url);
  }

  getDefaultMessage() {
    let url = this.hostName + "/api/ducking/duckingGetDefaultMessages";
    return this.http.get<any>(url);
  }

  listDuckingsFeed(page: any) {
    let url = this.hostName + "/api/ducking/listDuckingsFeed?page=" + page;
    return this.http.get<any>(url);
  }

  getDetailsByHandle(handle: any) {
    let url = this.hostName + "/api/ducking/getDetailsByHandle?handle=" + handle;
    return this.http.get<any>(url);
  }

  listMyProfileDucking(page: any) {
    let url = this.hostName + "/api/ducking/listMyProfileDucking?page=" + page;
    return this.http.get<any>(url);
  }

}
