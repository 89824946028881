import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { GoogleMaps, GoogleMap, GoogleMapsEvent } from '@ionic-native/google-maps/ngx';
import { JsMapsProviderService } from '../providers/js-maps-provider.service';
import { NativeMapsProviderService } from '../providers/native-maps-provider.service';


@Injectable({
  providedIn: 'root'
})
export class MapsProviderService {

  public map:any;

  constructor(public platform: Platform,) { 
    if(this.platform.is('cordova') && 
    (this.platform.is('ios') || this.platform.is('android'))){
    this.map = new NativeMapsProviderService(GoogleMaps);
  } else {
    this.map = new JsMapsProviderService();
  }
  }

  init(location:any, element:any){
    this.map.init(location, element);
  }
}
