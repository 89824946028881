import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { AlertController } from "@ionic/angular";
import { interval } from "rxjs";

@Injectable({
    providedIn: "root",
})

export class VersionUpdateCheckService {

    constructor(
        public updates: SwUpdate,
        private alertController: AlertController
    ) {
        console.log("app version update check service called.", updates.isEnabled);
        if (updates.isEnabled) {
            // check in 30 minutes interval
            interval(1000 * 60 * 30).subscribe(() => {
                console.log("updates.checkForUpdate() ---->");
                updates.checkForUpdate()
                    .then(() => {
                        console.log("checking for new updates in app -->");
                    })
            });
        }
    }

    public checkForUpdates(): void {
        this.updates.versionUpdates.subscribe((event) => {this.promptUser(event)});
    }

    async promptUser(event: any) {
        console.log("app version check --> ", event.type);
        if (event.type == "VERSION_DETECTED") {
            const alert = await this.alertController.create({
                header: 'Warning',
                message: "A newer version is now available. Refresh the page now to update the app",
                buttons: [{
                    text: 'Refresh',
                    handler: () => {
                        this.updates.activateUpdate().then(() => document.location.reload());
                    }
                }]
            });
            alert.present();
        }
    }

}
