import { Component, OnInit, OnChanges } from '@angular/core';
import { MenuController, NavController, Platform, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { GetServiceService } from './services/getService/get-service.service';
import { PostServiceService } from './services/postService/post-service.service';
import { pubnubKeys } from '../environments/environment';
import { PubNubAngular } from 'pubnub-angular2';
import { VersionUpdateCheckService } from './version-update-check.service';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
// import { FirebaseX } from "@ionic-native/firebase-x/ngx";

declare var PubNub: any
declare var cordova: any;
declare var UXCam: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnChanges {
  public pubnubMessage: any;
  your_appcomponent_member = '';
  public user: any;
  public maxWidth: any = '(min-width: 1025px)';

  constructor(
    private sidemenu: MenuController,
    private navCtrl: NavController,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private alertController: AlertController,
    public geolocation: Geolocation,
    public getService: GetServiceService,
    public postService: PostServiceService,
    public loadingController: LoadingController,
    private pubnub: PubNubAngular,
    private sw: VersionUpdateCheckService,
    private toastController: ToastController,
    // private fcm: FCM,
    // private firebase: FirebaseX
  ) {

    // this.firebase.getToken().then((token: any) => {
    //   console.log(`The token is ----> ${token}`);
    // })
    // this.firebase.onMessageReceived().subscribe((data: any) => {
    //   console.log(`FCM message: ----> ${data}`)
    // });

    this.sw.checkForUpdates();

    this.initializeApp();

    this.pubnub = pubnub;
    this.pubnub.init({
      publishKey: pubnubKeys.pubNubPublishKey,
      subscribeKey: pubnubKeys.pubNubSubscribeKey,
      uuid: pubnubKeys.channel,
      // logVerbosity: true,
      ssl: true
    });
  }

  ngOnInit() {
    console.log('app component ng on init -->');

    if (localStorage.getItem('isProducts')) {
      this.your_appcomponent_member = 'productsAvailable';
    }

    let localUserData = localStorage.getItem('user');
    // console.log('localUserData -->', localUserData);

    this.platform.ready().then(() => {
      console.log('platform is ready -->');

      if (this.platform.is('cordova')) {
        UXCam.optIntoSchematicRecordings() /* To enabled session video recording on iOS */
        UXCam.startWithKey("w38l59zzd3bmriy");
      }
      // if (this.platform.is('cordova')) {
      //   this.checkBackgroundPermission();
      // } else {
      //   this.getCurrentLocation();
      // }
    });

    // pubnub code started
    this.pubnub.subscribe({
      channels: [pubnubKeys.channel],
      triggerEvents: true,
      withPresence: true,
      autoload: 100
    });
    this.pubnub.getMessage(pubnubKeys.channel, (msg) => {
      if (msg.message.title === 'App Refresh') {
        console.log("App Refresh");
        console.log("data -->", JSON.parse(msg.message.data));
      }
    });
    console.log('before pubnub publish')
    this.pubnub.publish(
      {
        message: {
          such: 'Hello!'
        },
        channel: "happy-duckers",
      },
      function (status, response) {
        console.log('pubnub response from publish');
        console.dir(status);
        console.log(status)

        if (status.error) {
          console.log('pubnub error')
          console.dir(status);
          console.log(status)
          console.log('pubnub error detail ' + status.error);
          console.log(JSON.stringify(status, null, 4));
        } else {
          console.log("message Published w/ timetoken", response.timetoken)
        }
      }
    );
  }

  async alertPopup(title: string, msg: string) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: [{
        text: 'Okay'
      }]
    });
    await alert.present();
  }


  ngOnChanges() {
    this.user = localStorage.getItem('user');
    console.log('this.user onchanges', this.user);
  }

  private async checkBackgroundPermission() {
    try {
      console.log('line 28 --->');
      const resultBackground = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_BACKGROUND_LOCATION);
      const resultCoarse = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_COARSE_LOCATION);
      const resultFine = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION);
      console.log('line 30 bg --->', resultBackground, JSON.stringify(resultBackground));
      console.log('line 30 co --->', resultCoarse, JSON.stringify(resultCoarse));
      console.log('line 30 fine --->', resultFine, JSON.stringify(resultFine));
      if (!resultBackground || resultBackground.hasPermission === false) {
        this.requestPermissions();
      } else {
        this.getCurrentLocation();
      }
    } catch (error) {
      console.log("line 35 ---->", error, JSON.stringify(error));
      this.requestPermissions();
    }
  }

  private async requestPermissions() {
    try {
      console.log('line 42 --->');
      const data = await this.androidPermissions.requestPermissions([
        "android.permission.ACCESS_BACKGROUND_LOCATION",
        "android.permission.ACCESS_COARSE_LOCATION",
        "android.permission.INTERNET",
        "android.permission.ACCESS_NETWORK_STATE",
        this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
      ]);
      console.log('line 48 --->', data, JSON.stringify(data));
      if (!data.hasPermission) {
        throw new Error('No permission');
      } else {
        this.getCurrentLocation();
      }
    } catch (error) {
      console.log(
        'Background location',
        'We need background location access in order to continue.'
      );
    }
  }

  getCurrentLocation() {
    console.log("getCurrentLocation -->");
    this.geolocation.getCurrentPosition().then((resp: any) => {
      console.log('geolocation response', resp);
      console.log('geolocation response', JSON.stringify(resp));
      localStorage.setItem("userLocation", JSON.stringify({
        latitude: resp.coords.latitude,
        longitude: resp.coords.longitude
      }));
    }).catch((error: any) => {
      console.log('geolocation erroe', error);
      console.log('geolocation erroe', JSON.stringify(error));
    });
  }

  async logout() {

    const alert = await this.alertController.create({
      header: 'Warning',
      message: 'Are you sure you want to logout?',
      buttons: [{
        text: 'Yes',
        handler: () => {
          this.sidemenu.enable(false);
          localStorage.removeItem("user");
          this.navCtrl.navigateRoot("/login");
        }
      }, {
        text: 'No',
        handler: () => {
        }
      }]
    });
    alert.present()

    // if (localStorage.getItem('fcmDeviceId')) {
    //   this.postService.logout({ fcmDeviceId: localStorage.getItem('fcmDeviceId') }).subscribe(
    //     (res: any) => {
    //       console.log('logout --> ', res);
    //     }, (err: any) => {
    //       console.log('logout error --> ', err);
    //     });
    // }
  }

  redirect(url: any) {
    this.navCtrl.navigateRoot(url);
  }

  initializeApp() {
    this.platform.ready().then(async () => {

      if (this.platform.is("cordova")) {

        window.open = cordova.InAppBrowser.open;
        await FCM.getToken().then(token => {
          localStorage.setItem('fcmDeviceId', token);
          console.log(token, 'in here');
          // this.alertPopup('Device Id', localStorage.getItem('deviceId'))
        }).catch(err => {
          console.log('fcmtoken_err1', err);
          alert('getToken error: ' + err);
        });

        FCM.onTokenRefresh().subscribe(token => {
          localStorage.setItem('deviceId', token);
          console.log(token, 'in refresh');
          // this.alertPopup('Device Id Refresh', localStorage.getItem('deviceId'))
        });

        FCM.onNotification().subscribe((data: any) => {
          console.log('onNotification DATA -->', data);
          console.log('onNotification DATA -->', JSON.stringify(data));
          if (data.wasTapped) {
            console.log('Received in background');
            this.presentToast(data.title, "Received in background -> " + data.body, 'success', 3000);
          } else {
            console.log('Received in foreground');
            this.presentToast(data.title, "Received in foreground -> " + data.body, 'success', 3000);
          }
        });

        FCM.subscribeToTopic('peopleToTest');
      }
    });
  }

  async presentToast(header: any, message: any, color: any, duration: any) {
    const toast = await this.toastController.create({
      message: message,
      header: header,
      position: "top",
      color: color,
      duration: duration,
    });
    toast.present();
  }

}
