import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CloudinaryUploadComponent } from './CommonComponent/cloudinary-upload/cloudinary-upload.component';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HandleAvailabilityCheckComponent } from './handle-availability-check/handle-availability-check.component';
import { ConfirmationDialogComponent } from './CommonComponent/confirmation-dialog/confirmation-dialog.component';
import { ShareLocationToggleComponent } from './CommonComponent/share-location-toggle/share-location-toggle.component';
import { DuckingMessageComponent } from './ducking-message/ducking-message.component';

// show hide password
import { ShowHidePasswordComponent } from './CommonComponent/show-hide-password/show-hide-password.component';
import { ShareViaOptionsComponent } from './CommonComponent/share-via-options/share-via-options.component';
import { SharingConfirmationComponent } from './sharing-confirmation/sharing-confirmation.component';
import { ImageDialogComponent } from './CommonComponent/image-dialog/image-dialog.component';
import { PostDetailsComponent } from './post-details/post-details.component';


@NgModule({
  declarations: [
    CloudinaryUploadComponent,
    HandleAvailabilityCheckComponent,
    ConfirmationDialogComponent,
    ShareLocationToggleComponent,
    DuckingMessageComponent,
    ShareViaOptionsComponent,
    ShowHidePasswordComponent,
    SharingConfirmationComponent,
    ImageDialogComponent,
    PostDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatDialogModule,
  ],
  exports: [
    CloudinaryUploadComponent,
    HandleAvailabilityCheckComponent,
    ConfirmationDialogComponent,
    ShareLocationToggleComponent,
    DuckingMessageComponent,
    ShowHidePasswordComponent,
    ShareViaOptionsComponent,
    SharingConfirmationComponent,
    ImageDialogComponent,
    PostDetailsComponent
  ],
  entryComponents: [

  ],
  providers: [
    Camera,
    File,
    {
      provide: MatDialogRef,
      useValue: []
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: []
    },
  ],
})
export class ComponentsModule { }
